import React, { useContext, useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import queryString from "query-string";
import { Formik, useFormikContext } from "formik";
import { navigate } from "gatsby";
import styled from "styled-components";
import ContentBox from "../components/ContentBox";
import Form from "../components/Form";
import FormField from "../components/FormField";
import Label from "../components/Label";
import Input from "../components/Input";
import Button from "../components/buttons/Button";
import Link from "../components/Link";
import ActionBar from "../components/OldActionBar";
import DangerButton from "../components/buttons/DangerButton";
import Modal from "../components/Modal";
import FormError from "../components/FormError";
import PageWrapper from "../components/PageWrapper";
import UserContext from "../contexts/UserContext";
import Select from "../components/Select";
import {
  APPROVED,
  ARCHIVED,
  DRAFT,
  friendlyStatus,
  REVIEW,
} from "../constants";
import parseAnswer from "../util/parseAnswer";
import Textarea from "../components/Textarea";
import { REGULAR_TEXT } from "../colors";
import DarkPageHeading from "../components/DarkPageHeading";

const GET_TIEBREAKER_TEMPLATE = gql`
  query GetTiebreakerTemplate($id: ID!) {
    tiebreakerTemplate: getTiebreakerTemplate(id: $id) {
      id
      text
      answer
      answerValue
      status
    }
  }
`;

const SAVE_TIEBREAKER_TEMPLATE = gql`
  mutation SaveTiebreakerTemplate(
    $text: String!
    $answer: String!
    $answerValue: BigInt!
    $id: ID
    $status: String
  ) {
    saveTiebreakerTemplate(
      text: $text
      answer: $answer
      answerValue: $answerValue
      id: $id
      status: $status
    ) {
      tiebreakerTemplate {
        id
      }
    }
  }
`;

const DELETE_TIEBREAKER_TEMPLATE = gql`
  mutation DeleteTiebreakerTemplate($id: ID!) {
    deleteTiebreakerTemplate(id: $id) {
      success
    }
  }
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const WideFormField = styled(FormField)`
  width: 600px;
`;

const UneditableValue = styled.div`
  color: ${REGULAR_TEXT};
  font-weight: bold;
`;

const TiebreakerTemplateEditor = ({ id, originalValues, onDelete }) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    setFieldValue,
    submitForm,
    isSubmitting,
  } = useFormikContext();
  const { currentUser } = useContext(UserContext);
  const isSuperuser = currentUser && currentUser.isSuperuser;

  const [showDeleteModal, setShowDeleteModal] = useState();
  const [isDeleting, setIsDeleting] = useState();

  const isChanged = () =>
    values.text !== originalValues.text ||
    values.answer !== originalValues.answer ||
    values.status !== originalValues.status;

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
  };

  const handleConfirmDelete = async () => {
    setIsDeleting(true);
    await onDelete();
  };

  return (
    <ContentBox direction="column">
      <DarkPageHeading>
        {!id ? "Create Tiebreaker" : "Edit Tiebreaker"}
      </DarkPageHeading>
      <FormWrapper>
        <Form>
          <WideFormField>
            <Label htmlFor="text">Text</Label>
            <Textarea
              type="text"
              name="text"
              autoComplete="off"
              value={values.text}
              onChange={handleChange}
            />
            {errors.text && touched.text && (
              <FormError>{errors.text}</FormError>
            )}
          </WideFormField>
          <WideFormField>
            <Label htmlFor="answer">Answer</Label>
            <Input
              type="answer"
              name="answer"
              autoComplete="off"
              value={values.answer}
              onChange={handleChange}
            />
            {errors.answer && touched.answer && (
              <FormError>{errors.answer}</FormError>
            )}
          </WideFormField>
          <FormField>
            <Label>Status</Label>
            {isSuperuser && (
              <Select
                name="status"
                value={{
                  value: values.status,
                  label: friendlyStatus[values.status],
                }}
                options={[
                  { value: DRAFT, label: friendlyStatus[DRAFT] },
                  { value: REVIEW, label: friendlyStatus[REVIEW] },
                  { value: APPROVED, label: friendlyStatus[APPROVED] },
                  { value: ARCHIVED, label: friendlyStatus[ARCHIVED] },
                ]}
                onChange={(option) => setFieldValue("status", option.value)}
              />
            )}
            {!isSuperuser && <UneditableValue>{values.status}</UneditableValue>}
          </FormField>
        </Form>
      </FormWrapper>
      <ActionBar>
        <Button
          size="large"
          disabled={!isChanged() || isSubmitting}
          onClick={() => submitForm()}
        >
          {isSubmitting ? "Saving" : "Save"}
        </Button>
        <Link to={"/question-database"}>
          <Button size="large">Close</Button>
        </Link>
        <DangerButton size="large" disabled={!id} onClick={handleDeleteClick}>
          Delete Tiebreaker
        </DangerButton>
      </ActionBar>
      {showDeleteModal && (
        <Modal
          title="Delete Tiebreaker"
          text="Delete this tiebreaker and all the related questions?"
          buttons={
            <>
              <DangerButton
                size="large"
                disabled={isDeleting}
                onClick={handleConfirmDelete}
              >
                {isDeleting ? "Deleting" : "Delete"}
              </DangerButton>
              <Button size="large" onClick={handleCancelDelete}>
                Cancel
              </Button>
            </>
          }
        />
      )}
    </ContentBox>
  );
};

export default function CreateEditTiebreakerTemplate({ location }) {
  const { id } = queryString.parse(location.search);

  const { data, refetch } = useQuery(GET_TIEBREAKER_TEMPLATE, {
    variables: {
      id,
    },
    skip: !id,
  });

  const [saveTiebreakerTemplate] = useMutation(SAVE_TIEBREAKER_TEMPLATE);
  const [deleteTiebreakerTemplate] = useMutation(DELETE_TIEBREAKER_TEMPLATE);

  if (id && !data) {
    return null;
  }

  const originalValues = (data && data.tiebreakerTemplate) || {};

  const initialValues = {
    text: data && data.tiebreakerTemplate && data.tiebreakerTemplate.text,
    answer: data && data.tiebreakerTemplate && data.tiebreakerTemplate.answer,
    status: data && data.tiebreakerTemplate && data.tiebreakerTemplate.status,
  };

  const validate = (values) => {
    const errors = {};
    if (!values.text) {
      errors.text = "You must enter text for the tiebreaker";
    }
    if (!values.answer) {
      errors.answer = "You must enter an answer to the tiebreaker";
    } else if (isNaN(parseAnswer(values.answer))) {
      errors.answer = "You must enter a number";
    }
    return errors;
  };

  const handleSubmit = async (values) => {
    const result = await saveTiebreakerTemplate({
      variables: {
        id,
        text: values.text,
        answer: values.answer,
        answerValue: parseAnswer(values.answer),
        status: values.status,
      },
    });
    const newTiebreakerTemplateId =
      result.data.saveTiebreakerTemplate.tiebreakerTemplate.id;
    await navigate(
      `/create-edit-tiebreaker-template?id=${newTiebreakerTemplateId}`
    );
    await refetch({ id: newTiebreakerTemplateId });
  };

  const handleDelete = async () => {
    await deleteTiebreakerTemplate({
      variables: {
        id,
      },
    });

    await navigate("/question-database");
  };

  return (
    <PageWrapper>
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={handleSubmit}
      >
        <TiebreakerTemplateEditor
          id={id}
          originalValues={originalValues}
          onDelete={handleDelete}
        />
      </Formik>
    </PageWrapper>
  );
}
